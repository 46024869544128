import { useState, useEffect } from 'react';
import './App.css'; // Import your CSS file for the modal styles

const UserLevelStatus = () => {
  // Adjust the useState initialization to accept number or null
const [soldC, setSoldC] = useState<number | null>(null);
const [creditC, setCreditC] = useState<number | null>(null);

  const [levelInfo, setLevelInfo] = useState<{
    level: string;
    imgSource: string;
    colorLevel:string;
    diff: number;
    nextLevel: string;
  }>({
    level: '',
    imgSource: '',
    colorLevel: '#363636',
    diff: 0,
    nextLevel: '',
  });



  useEffect(() => {
    const walletAddress = localStorage.getItem('walletAddress');
    if (!walletAddress) {
      //console.log('No wallet address found.');
      return;
    }
  
    const fetchCreditC = async () => {
      try {
        const response = await fetch(`https://peaxel.me/wp-json/custom/v1/get-metadata-by-metadata/?metadata_name_A=wallet_address&value_metadata_name_A=${walletAddress}&metadata_name_B=credits_pxl`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        const creditCValue: number = Number(data.meta_value_B);
        setCreditC(creditCValue); // Set `soldC` which triggers the useEffect below
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
  
    fetchCreditC();
  }, []);



// Inside your useEffect where you fetch `soldC`:
useEffect(() => {
    const walletAddress = localStorage.getItem('walletAddress');
    if (!walletAddress) {
      //console.log('No wallet address found.');
      return;
    }
  
    const fetchSoldC = async () => {
      try {
        const response = await fetch(`https://peaxel.me/wp-json/custom/v1/get-metadata-by-metadata/?metadata_name_A=wallet_address&value_metadata_name_A=${walletAddress}&metadata_name_B=SoldC`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        const soldCValue: number = Number(data.meta_value_B);
        setSoldC(soldCValue); // Set `soldC` which triggers the useEffect below
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
  
    fetchSoldC();
  }, []);
  
  // New useEffect to call `determineLevel` when `soldC` changes:
  useEffect(() => {
    if (soldC !== null) { // Ensure `soldC` is not null before calling `determineLevel`
      determineLevel(soldC);
    }
  }, [soldC]); // Dependency array ensures this effect runs whenever `soldC` changes
  


  const determineLevel = (soldC: number) => {
    let level: string = 'Unknown'; // Default value to ensure level is always a string
    let imgSource: string = ''; // Default value as empty string
    let diff: number = 0; // Ensure diff is always a number
    let nextLevel: string = 'None'; // Default value if there's no next level
    let colorLevel: string = 'None'; 
  
    if (soldC >= 0 && soldC < 10) {
        level = 'Discover';
        imgSource = 'https://peaxel.me/wp-content/uploads/2024/03/n81.png';
        diff = 10 - soldC;
        nextLevel = 'Starter';
        colorLevel = '#05AA22';
      } else if (soldC >= 10 && soldC < 25) {
        level = 'Starter';
        imgSource = 'https://peaxel.me/wp-content/uploads/2024/03/82.png';
        diff = 25 - soldC;
        nextLevel = 'Gamer';
        colorLevel = '#DC37D9';
      } else if (soldC >= 25 && soldC < 50) {
        level = 'Gamer';
        imgSource = 'https://peaxel.me/wp-content/uploads/2024/03/83.png';
        diff = 50 - soldC;
        nextLevel = 'Competitor';
        colorLevel = '#18CE12';
      } else if (soldC >= 50 && soldC < 100) {
        level = 'Competitor';
        imgSource = 'https://peaxel.me/wp-content/uploads/2024/03/84.png';
        diff = 100 - soldC;
        nextLevel = 'Striker';
        colorLevel = '#CE29C4';
      } else if (soldC >= 100 && soldC < 150) {
        level = 'Striker';
        imgSource = 'https://peaxel.me/wp-content/uploads/2024/03/85.png';
        diff = 150 - soldC;
        nextLevel = 'Warrior';
        colorLevel = '#139ADC';
      } else if (soldC >= 150 && soldC < 200) {
        level = 'Warrior';
        imgSource = 'https://peaxel.me/wp-content/uploads/2024/03/86.png';
        diff = 200 - soldC;
        nextLevel = 'Winner';
        colorLevel = '#1BA1E4';
    } else if (soldC >= 200 && soldC < 250) {
        level = 'Winner';
        imgSource = 'https://peaxel.me/wp-content/uploads/2024/03/87.png';
        diff = 250 - soldC;
        nextLevel = 'King';
        colorLevel = '#E41040';
      } else if (soldC >= 250) {
        level = 'King';
        imgSource = 'https://peaxel.me/wp-content/uploads/2024/03/88.png';
        colorLevel = '#179FE3';
        // Assuming there's no next level after Legend, you might not need diff and nextLevel.
        // But setting them to some default or final value depending on your UI's needs.
        diff = 0; // No more levels to achieve, could also choose not to display this.
        nextLevel = 'Max'; // Indicate max level achieved, or alternatively, don't display it.
      }
  
    // Now, since all variables are guaranteed to be initialized,
    // TypeScript should not complain when setting the state
    setLevelInfo({ level, imgSource, colorLevel, diff, nextLevel });
  };
  

  return (
    <div className="game_modal">
    <div className="levelDiv">
    
    <div className="levelTop">
      <span className="levelLogo">{levelInfo.imgSource && <img src={levelInfo.imgSource} alt="Level Icon" width="80" height="80" />}</span>
    
      <span className="levelStatus" 
      style={{
        color: '#FFFFFF',
        textShadow: `0 0 5px ${levelInfo.colorLevel}, 0 0 10px ${levelInfo.colorLevel}, 0 0 20px ${levelInfo.colorLevel}, 0 0 40px ${levelInfo.colorLevel}`
      }}>
        {levelInfo.level}</span>
      
      
      {soldC && <span className="levelPoints">{soldC} points</span>}
      {levelInfo.nextLevel && <span style={{opacity: '0.7' }}>Just {levelInfo.diff} points to advance to <strong>{levelInfo.nextLevel}</strong></span>}
      
      </div>
      <div className="levelBar"  >
        <div style={{ width: '100%', backgroundColor: '#fafafaed', borderRadius: '10px' }}>
        <div style={{ 
    width: `${((soldC || 4) / ((soldC || 4)+levelInfo.diff)) * 100}%`, 
    backgroundColor: levelInfo.colorLevel, 
    height: '2px', 
    borderRadius: '2px',
    boxShadow: `0px 0px 8px ${levelInfo.colorLevel}`
  }}></div>
      </div>
      </div>
      
    </div>


    <div className="levelDiv">
    <div className="levelTop">
      <span className="levelLogo">{levelInfo.imgSource && <img src="https://peaxel.me/wp-content/uploads/2024/03/coins_pxl-1.jpg" alt="Level Icon" width="80" height="80" />}</span>
    
      
      {creditC && <span className="levelStatus" 
      style={{
        color: '#FFFFFF',
        textShadow: `0 0 5px #E3A520, 0 0 10px #E3A520, 0 0 20px #E3A520, 0 0 40px #E3A520`
      }}>
        {creditC} Coins</span>}

     {creditC && <span style={{opacity: '0.7' }}>Enhance your Strategy.</span>}

     <span className="coins-button"><i className="fa-solid fa-store"></i>&nbsp;&nbsp;Visit Store</span>
      
       
      </div>
        </div>
    </div>
  );
};

export default UserLevelStatus;
